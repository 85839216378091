<template>
    <span>{{item.id}}<br/><span class="text-gray-300">{{item.vehicle_id}}</span></span>
</template>

<script>
export default {
  name: "base-column-id",
  props: ['column', 'item'],
}
</script>

<style scoped>

</style>